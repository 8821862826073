import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import GalleryTile from "../atoms/GalleryTile";

const Partners = (props) => {
  return (
    <>
      {props.partners?.length !== 0 ? (
        <>
          <Stack textAlign="center" py={4}>
            {props.layout.sectionTitles && (
              <Typography variant="h2" color="primary.light">
                Partners
              </Typography>
            )}
          </Stack>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={3} justifyContent="center">
              {props.partners.map((partner, index) => {
                return (
                  <GalleryTile
                    key={"partner-" + index}
                    image={props.images[index]?.node?.fluid?.src}
                  />
                );
              })}
            </Grid>
          </Box>
        </>
      ) : undefined}
    </>
  );
};

export default Partners